<template>
  <router-view></router-view>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { removeAscent } from "@/core/helpers/stringHandling";

export default {
  name: "home",
  components: {},
  async created() {
    if (this.$router.currentRoute.name === "home") {
      const { data } = await ApiService.get("/room/getList");
      const roomList = data.data.map((item) => {
        item.roomLink = removeAscent(item.roomName.replace(/ /g, ""));
        return item;
      });
      const firstRoom = roomList[0];
      this.$router.push({ path: `/${firstRoom.id}/${firstRoom.roomLink}` });
    }
  },
  methods: {},
};
</script>
